import { css } from "@emotion/core"
import { h2 } from "css/primitives"
import { colors } from "css/theme"
import { graphql } from "gatsby"
import React from "react"

import "src/queries/featured_media"
import PageLayout from "src/components/PageLayout"
import { PostCard } from "../../components"
import SEO from "../../components/seo"
import heroImage from "images/red-team-chronicles/hero-image.jpg"

const wrapper = css`
  padding-bottom: 100px;
  overflow: hidden;
  background-color: ${colors.darkBackground};
  @media (max-width: 479px) {
    padding-bottom: 75px;
  }
`

const wrapperTop = css`
  background-image: url(${heroImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 120px;
  padding-bottom: 100px;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  @media (max-width: 576px) {
    padding-top: 100px;
    padding-bottom: 0px;
  }
`

const heading = [
  h2,
  css`
    line-height: 1.17;
    font-size: 48px;
    color: white;
    text-align: center;
    margin-bottom: 0.5rem;
  `,
]

const headingText = css`
  color: white;
  font-size: 21px;
  line-height: 1.5;
  text-align: center;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0;
  @media (max-width: 576px) {
    font-size: 18px;
  }
`

const blogListWrapper = css`
  padding-top: 82px;
  @media (max-width: 1023px) {
    padding-top: 40px;
  }
`

const blogList = css`
  list-style-type: none;
  margin-left: 0;
  display: flex;
  flex-wrap: wrap;
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 576px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const IndexPage = ({ data }) => {
  const posts = data.allWordpressWpRedTeamChronicles.edges
  return (
    <PageLayout>
      <SEO
        title="Red Team Chronicles"
        description="Real-life anonymised accounts of how our red team broke into some of the world’s most well defended orgnaisations. Moral of the story? There’s no stopping a motivated adversary. Start thinking like them to stop them."
        image={heroImage}
      />
      <div css={wrapper}>
        <div css={wrapperTop}>
          <h1 css={heading}>Red Team Chronicless</h1>
          <p css={headingText}>
            Real-life anonymised accounts of how our red team broke into some of
            the world’s most well defended orgnaisations. Moral of the story?
            There’s no stopping a motivated adversary. Start thinking like them
            to stop them.
          </p>
        </div>
        <div css={blogListWrapper}>
          <ul css={blogList}>
            {posts.map((document, index) => (
              <PostCard post={document.node} key={index} />
            ))}
          </ul>
        </div>
      </div>
    </PageLayout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query RedTeamChroniclesListQuery {
    allWordpressWpRedTeamChronicles {
      edges {
        node {
          id
          slug
          title
          content
          excerpt
          status
          wordpress_id
          ...FeaturedMediaRedTeam
          author {
            name
          }
        }
      }
    }
  }
`
